<template>
  <div>
    <h5 class="text-center">Consultas / Preço</h5>
    <table
      class="table table-sm table-bordered table-striped table-hover"
      style="font-size: 8px; cursor: pointer"
    >
      <thead>
        <tr class="bg-light">
          <th>Valor</th>
          <th>Consulta</th>
          <th>Qtd</th>
          <th>Venda</th>
          <th>Custo</th>
          <th>Lucro</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="c in dados.resumo"
          :key="`cons_lin_preco_${c.exame}_${Math.random(2323232)}`"
          @click="openDetalhes(c)"
        >
          <th class="bg-light">{{ c.valor | currency }}</th>
          <th class="text-left" style="font-size:6px">{{ c.exame }}</th>
          <td>{{ c.qtd }}</td>
          <td>{{ c.vl_total | currency }}</td>
          <td>{{ c.vl_custo | currency }}</td>
          <td>{{ c.vl_lucro | currency }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="2">TOTAL</th>
          <th>
            {{
              dados.resumo.reduce((ret, vl) => {
                ret += vl.qtd;
                return ret;
              }, 0)
            }}
          </th>
          <th>
            {{
              dados.resumo.reduce((ret, vl) => {
                ret += vl.vl_total;
                return ret;
              }, 0) | currency
            }}
          </th>
          <th>
            {{
              dados.resumo.reduce((ret, vl) => {
                ret += vl.vl_custo;
                return ret;
              }, 0) | currency
            }}
          </th>
          <th>
            {{
              dados.resumo.reduce((ret, vl) => {
                ret += vl.vl_lucro;
                return ret;
              }, 0) | currency
            }}
          </th>
        </tr>
      </tfoot>
    </table>
    <b>Clique na célula desejada para abrir detalhes</b>

    <b-modal
      size="lg"
      id="modal-detalhe-comparativo-porPreco"
      title="Detalhes"
      hide-footer
    >
      <div class="text-right">
        <b-btn @click="imprimirDetalhes"><b-icon-printer /> Imprimir</b-btn>
      </div>
      <PrintComponent
        v-if="printDetails"
        :printNow="true"
        :show="showDetails"
        @done="donePrintDetails"
        :titulo="`Detalhes Consultas Por Preço`"
        :filtros="detalhesHeader"
        :orientationPDF="'portrait'"
      >
       <div class="bg-light row m-2 font-weight-bold p-3 text-center">
          <div class="col-3">
            Quantidade
            <h3>{{ detalhes.qtd }}</h3>
          </div>
          <div class="col-3">
            Venda
            <h3>
              {{
                detalhes.vl_total | currency
              }}
            </h3>
          </div>
          <div class="col-3">
            Custo
            <h3>
              {{
                detalhes.vl_custo | currency
              }}
            </h3>
          </div>
          <div class="col-3">
            Lucro
            <h3>
              {{
                detalhes.vl_lucro | currency
              }}
            </h3>
          </div>
        </div>
        <b-table
          :items="detalhes.detalhes"
          small
          stacked="sm"
          style="font-size: 10px"
          :fields="[
            { key: 'id', label: 'Cód.' },
            { key: 'medico', label: 'Medico' },
            { key: 'exame', label: 'Exame' },
            { key: 'paciente', label: 'Paciente' },
            { key: 'vl_total', label: 'Valor' },
            { key: 'vl_custo', label: 'Custo' },
          ]"
        >
          <template #cell(vl_total)="row">
            {{ row.item.vl_total | currency }}
          </template>
          <template #cell(vl_custo)="row">
            {{ row.item.vl_custo | currency }}
          </template>
        </b-table>
      </PrintComponent>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import PrintComponent from "../../../components/Print/PrintComponent.vue";
export default {
  components: {
    PrintComponent,
  },
  props: {
    dados: Object,
    filtros: Object,
  },
  mounted() {},
  data() {
    return {
      detalhes: [],
      detalhesHeader: "",
      showDetails: true,
      printDetails: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    donePrintDetails() {
      this.showDetails = true;
    },
    imprimirDetalhes() {
      this.printDetails = false;
      this.showDetails = false;
      setTimeout(() => {
        this.printDetails = true;
      }, 300);
    },
    openDetalhes(c) {
      this.printDetails = false;
      this.showDetails = true;

      this.detalhesHeader = `Periodo: ${moment(
        this.filtros.periodo.dt_i
      ).format("DD/MM/YYYY")} até ${moment(this.filtros.periodo.dt_f).format(
        "DD/MM/YYYY"
      )} | ${c.exame} - ${this.formatFloatToReal(c.valor)}`;

      this.$nextTick();
      setTimeout(() => {
        this.detalhes = c;
        this.printDetails = true;

        this.$bvModal.show("modal-detalhe-comparativo-porPreco");
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>