<template>
  <div style=" max-width: 100%;">
    <h5 class="text-center">Especialidades X Clínica</h5>

    <table
      class="table table-sm table-bordered table-striped table-hover"
      style="font-size: 8px; cursor: pointer; max-width: 100%;"
    >
      <thead>
        <tr>
          <th></th>
          <th
            class="text-center bg-light"
            v-for="clin of dados.clinicas"
            :key="`col_clin${clin.id}_relatGrade_${Math.random(2022)}`"
            @click.prevent="abrirDetalhes(clin.id, null)"
          >
            {{ clin.sigla }}
          </th>
          <th @click.prevent="abrirDetalhes(null, null)"><b>TOTAL</b></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="espec in dados.especialidades"
          :key="`row_espe${espec.id}_${Math.random(232232)}`"
         
        >
          <th class="bg-light text-left" @click="abrirDetalhes(null, espec.id)"  style="max-width: 80px;     word-break: break-word;">
            {{ espec.especialidade }}
          </th>
          <td
            v-for="clin of dados.clinicas"
            :key="
              `row_${espec.id}_col_clin${clin.id}_relatGrade_${Math.random(
                2022
              )}`
            "
            @click.prevent="abrirDetalhes(clin.id, espec.id)"
          >
            {{
              dados.lista.filter(
                (x) => x.especialidade_id == espec.id && x.empresa_id == clin.id
              ).length || ""
            }}
          </td>
          <td @click="abrirDetalhes(null, espec.id)">
            <b>
              {{
                dados.lista.filter((x) => x.especialidade_id == espec.id)
                  .length || ""
              }}
            </b>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="bg-light">
          <th><b>TOTAL</b></th>
          <th
            class="text-center bg-light"
            v-for="clin of dados.clinicas"
            :key="`col_total_clin${clin.id}_relatGrade_${Math.random(2022)}`"
            @click.prevent="abrirDetalhes(clin.id, null)"
          >
            {{
              dados.lista.filter((x) => x.empresa_id == clin.id).length || ""
            }}
          </th>
          <th @click.prevent="abrirDetalhes(null, null)">
            <b>
              {{ dados.lista.length || "" }}
            </b>
          </th>
        </tr>
      </tfoot>
    </table>
    <b>Clique na célula desejada para abrir detalhes</b>
    <b-modal
      size="lg"
      id="modal-detalhe-comparativo-clin-espec"
      title="Detalhes"
      hide-footer
    >
      <div class="text-right">
        <b-btn @click="imprimirDetalhes"><b-icon-printer /> Imprimir</b-btn>
      </div>
      <PrintComponent
        v-if="printDetails"
        :printNow="true"
        :show="showDetails"
        @done="donePrintDetails"
        :titulo="`Detalhes Comparativo Consultas`"
        :filtros="detalhesHeader"
        :orientationPDF="'portrait'"
      >
        <div class="bg-light row m-2 font-weight-bold p-3 text-center">
          <div class="col-4">
            Quantidade
            <h3>{{ detalhes.length }}</h3>
          </div>
          <div class="col-4">
            Venda
            <h3>
              {{
                detalhes.reduce((ret, vl) => {
                  ret += vl.vl_total;
                  return ret;
                }, 0) || 0 | currency
              }}
            </h3>
          </div>
          <div class="col-4">
            Custo
            <h3>
              {{
                detalhes.reduce((ret, vl) => {
                  ret += vl.vl_custo;
                  return ret;
                }, 0) || 0 | currency
              }}
            </h3>
          </div>
        </div>

        <hr />
        <b-table
          :items="detalhes"
          small
          stacked="sm"
          :fields="[
            { key: 'id', label: 'Cód.' },
            { key: 'medico', label: 'Medico' },
            { key: 'especialidade.especialidade', label: 'Especialidade' },
            { key: 'exame', label: 'Exame' },
            { key: 'paciente', label: 'Paciente' },
            { key: 'vl_total', label: 'Valor' },
            { key: 'vl_custo', label: 'Custo' },
          ]"
          style="font-size: 10px"
        >
          <template #cell(id)="row">
            {{ row.item.id }}<br />
            <small>
              {{ row.item.data | moment("DD/MM/YYYY") }}
            </small>
          </template>

          <template #cell(vl_total)="row">
            {{ row.item.vl_total | currency }}
          </template>
          <template #cell(vl_custo)="row">
            {{ row.item.vl_custo | currency }}
          </template>
        </b-table>
      </PrintComponent>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import PrintComponent from "../../../components/Print/PrintComponent.vue";
export default {
  components: { PrintComponent },
  props: {
    dados: Object,
    filtros: Object,
  },
  mounted() {},
  data() {
    return {
      detalhes: [],
      detalhesHeader: "",
      showDetails: true,
      printDetails: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    donePrintDetails() {
      this.showDetails = true;
    },
    imprimirDetalhes() {
      this.printDetails = false;
      this.showDetails = false;
      setTimeout(() => {
        this.printDetails = true;
      }, 300);
    },
    abrirDetalhes(clin, espec) {
      console.log(123123, this.dados);
      this.detalhesHeader = `Periodo: ${moment(
        this.filtros.periodo.dt_i
      ).format("DD/MM/YYYY")} até ${moment(this.filtros.periodo.dt_f).format(
        "DD/MM/YYYY"
      )}`;
      if (clin) {
        this.detalhesHeader += ` | Clínica: ${
          this.dados.clinicas.find((x) => x.id == clin)?.sigla
        }`;
      }
      if (espec) {
        this.detalhesHeader += ` | Especialidade: ${
          this.dados.especialidades.find((x) => x.id == espec)?.especialidade
        }`;
      }
      this.detalhes = this.dados.lista.filter(
        (x) =>
          (!clin || (clin && clin == x.empresa_id)) &&
          (!espec || espec == x.especialidade_id)
      );
      this.showDetails = true;
      this.printDetails = true;
      this.$bvModal.show("modal-detalhe-comparativo-clin-espec");
    },
  },
};
</script>

<style lang="scss" scoped></style>
