<template>
  <div class="container">
    <div class="card mt-4" style="max-width: 800px; margin: 0 auto !important">
      <div class="card-body">
        <Filtros :title="`Comparativo de Consultas`" @filtrar="carregar">
        </Filtros>
      </div>
    </div>
    <hr />
    <div class="row mt-4" v-if="result">
      <div class="col-12">
        <div style="width: 100%; max-width: 1200px; margin: 0 auto">
          <div class="w-100 text-right">
            <b-btn @click="print"><b-icon-printer /> Imprimir</b-btn>
          </div>
          <div id="printDiv">
            <PrintComponent
              v-if="printShow"
              :printNow="true"
              :show="showDetails"
              @done="donePrint"
              :titulo="`Detalhes Comparativo Consultas`"
              :filtros="header"
              :orientationPDF="'landscape'"
            >
              <div class="row">
                <div class="col-4 pr-1">
                  <comparativo-tabela-por-clinica-espec
                    :dados="result.GradePorClinicaEspecialidade"
                    :filtros="fils"
                  />
                </div>
                <div class="col-4 pr-1">
                  <comparativo-tabela-por-preco
                    :dados="result.ConsultasPorValor"
                    :filtros="fils"
                  />
                </div>
                <div class="col-4">
                  <comparativo-tabela-cartoes
                    :dados="result.VendasDeCartao"
                    :dados2="result.VendasOftalmotono"
                    :filtros="fils"
                  />
                </div>
              </div>
            </PrintComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filtros from "../../../components/Relatorios/Filtros.vue";
import RelatoriosLib from "../../../libs/RelatoriosLib";
import ComparativoTabelaPorClinicaEspec from "./ComparativoTabelaPorClinicaEspec.vue";
import PrintComponent from "../../../components/Print/PrintComponent.vue";
import ComparativoTabelaPorPreco from "./ComparativoTabelaPorPreco.vue";
import ComparativoTabelaCartoes from "./ComparativoTabelaCartoes.vue";
import moment from "moment";
import PrinterWindowHelper from "../../../helpers/PrinterWindowHelper";

export default {
  components: {
    Filtros,
    ComparativoTabelaPorClinicaEspec,
    PrintComponent,
    ComparativoTabelaPorPreco,
    ComparativoTabelaCartoes,
  },
  props: {},
  mounted() {},
  data() {
    return {
      result: [],
      fils: null,
      printShow: false,
      showDetails: true,
      header: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    print() {
      if (this.printShow) {
      PrinterWindowHelper(document.getElementById("printDiv").innerHTML,5,true);

      } else {
        this.printShow = false;
        this.showDetails = false;
        setTimeout(() => {
          this.printShow = true;
        }, 200);
      }
    },
    donePrint() {
      this.showDetails = true;
    },
    async carregar(filtros) {
      this.printShow = false;
      this.header = `Periodo ${moment(filtros.periodo.dt_i).format(
        "DD/MM/YYYY"
      )} até ${moment(filtros.periodo.dt_f).format("DD/MM/YYYY")}`;

      this.$nextTick();

      setTimeout(async () => {
        this.fils = filtros;
        this.result =
          (await RelatoriosLib.RelatorioComparativoConsultas(filtros))?.data ||
          [];

        this.printShow = true;
        this.showDetails = true;

        console.log("resaaaaaaa", this.result);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped></style>
