<template>
  <div style="max-width: 100%;">
    <h5 class="text-center">Vendas de Cartão</h5>
    <table
      class="table table-sm table-bordered table-striped table-hover"
      style="font-size: 8px; cursor: pointer; max-width: 100%;"
    >
      <thead>
        <tr class="bg-light">
          <th>Operador</th>
          <th>Total</th>
          <th
            v-for="ex in dados.exames"
            :key="`col_head_carts_${ex.exame}_${ex.valor}_${Math.random(
              23232
            )}`"
            @click.prevent="openDetalhes(1, ex)"
          >
            {{ ex.exame }}
            {{ ex.valor | currency }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="usu in dados.usuarios"
          :key="`lin_relat_cart_usu_${usu.usuario}_${Math.random(23232)}`"
        >
          <th class="bg-light" @click="openDetalhes(1, null, usu)">
            {{ usu.usuario }}
          </th>
          <th class="bg-light" @click="openDetalhes(1, null, usu)">
            {{ usu.qtd }} <br />
            <small>{{ usu.vl_total | currency }}</small>
          </th>
          <th
            v-for="ex in dados.exames"
            :key="`col_head_carts_${ex.exame}_${ex.valor}_DETALHE_${Math.random(
              23232
            )}`"
            @click="openDetalhes(1, ex, usu)"
          >
            {{
              dados.lista.find(
                (x) =>
                  x.usuario == usu.usuario &&
                  x.exame == ex.exame &&
                  x.valor == ex.valor
              )
                ? dados.lista.find(
                    (x) =>
                      x.usuario == usu.usuario &&
                      x.exame == ex.exame &&
                      x.valor == ex.valor
                  ).qtd
                : ""
            }}<br />
            <small>
              {{
                dados.lista.find(
                  (x) =>
                    x.usuario == usu.usuario &&
                    x.exame == ex.exame &&
                    x.valor == ex.valor
                )
                  ? dados.lista.find(
                      (x) =>
                        x.usuario == usu.usuario &&
                        x.exame == ex.exame &&
                        x.valor == ex.valor
                    ).vl_total
                  : ""
              }}
            </small>
          </th>
        </tr>
      </tbody>
      <tfoot>
        <tr class="bg-light">
          <th @click="openDetalhes(1, null, null)">Total</th>
          <th @click="openDetalhes(1, null, null)">
            {{
              dados.lista.reduce((ret, vl) => {
                ret += vl.qtd;
                return ret;
              }, 0)
            }}
            <br />
            <small>
              {{
                dados.lista.reduce((ret, vl) => {
                  ret += vl.vl_total;
                  return ret;
                }, 0) | currency
              }}
            </small>
          </th>
          <th
            v-for="ex in dados.exames"
            :key="`col_head_carts_${ex.exame}_${ex.valor}_RESUMO_${Math.random(
              23232
            )}`"
            @click="openDetalhes(1, ex)"
          >
            {{
              dados.lista
                .filter((x) => x.exame == ex.exame && x.valor == ex.valor)
                .reduce((ret, vl) => {
                  ret += vl.qtd;
                  return ret;
                }, 0)
            }}
            <br />
            <small>
              {{
                dados.lista
                  .filter((x) => x.exame == ex.exame && x.valor == ex.valor)
                  .reduce((ret, vl) => {
                    ret += vl.vl_total;
                    return ret;
                  }, 0) | currency
              }}
            </small>
          </th>
        </tr>
      </tfoot>
    </table>
    <b>Clique na célula desejada para abrir detalhes</b>
    <hr />
    <h5 class="text-center">Consultas Oftalmo / Tonometria</h5>

    <table
      class="table table-sm table-bordered table-striped table-hover"
      style="font-size: 8px; cursor: pointer"
    >
      <thead>
        <tr>
          <th>Operador</th>
          <th @click.prevent="openDetalhes(2)">Total</th>
          <th
            v-for="ex in dados2.exames"
            :key="`col_comp_folt_th_${ex.exame}_${Math.random(23232)}`"
            @click.prevent="openDetalhes(2, ex.exame)"
          >
            {{ ex.exame }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="usu in dados2.usuarios"
          :key="`lin_comp_oft_usu_td_${usu.usuario}_${Math.random(12112131)}`"
        >
          <td
            class="text-left"
            @click.prevent="openDetalhes(2, null, usu.usuario)"
          >
            {{ usu.usuario }}
          </td>
          <td @click.prevent="openDetalhes(2, null, usu.usuario)">
            {{ dados2.lista.filter((x) => x.usuario == usu.usuario).length }}
          </td>
          <td
            v-for="ex in dados2.exames"
            :key="`col_comp_folt_td_lin_${ex.exame}_${Math.random(23232)}`"
            @click.prevent="openDetalhes(2, ex.exame, usu.usuario)"
          >
            {{
              dados2.lista.filter(
                (x) => x.usuario == usu.usuario && x.ex == ex.exame
              ).length
            }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="bg-light">
          <th @click.prevent="openDetalhes(2, ex.exame, usu.usuario)">TOTAL</th>
          <th @click.prevent="openDetalhes(2, ex.exame, usu.usuario)">
            {{ dados2.lista.length }}
          </th>
          <th
            v-for="ex in dados2.exames"
            :key="`col_comp_folt_th_foot_${ex.exame}_${Math.random(23232)}`"
            @click.prevent="openDetalhes(2, ex.exame)"
          >
            {{ dados2.lista.filter((x) => x.ex == ex.exame).length }}
          </th>
        </tr>
      </tfoot>
    </table>

    <b>Clique na célula desejada para abrir detalhes</b>

    <b-modal
      size="lg"
      id="modal-detalhe-comparativo-cartoes"
      title="Detalhes"
      hide-footer
    >
      <div class="text-right">
        <b-btn @click="imprimirDetalhes"><b-icon-printer /> Imprimir</b-btn>
      </div>
      <PrintComponent
        v-if="printDetails"
        :printNow="true"
        :show="showDetails"
        @done="donePrintDetails"
        :titulo="`Detalhes Consultas Por Preço`"
        :filtros="detalhesHeader"
        :orientationPDF="'portrait'"
      >
        <div class="bg-light row m-2 font-weight-bold p-3 text-center">
          <div class="col-3">
            Quantidade
            <h3>{{ detalhes.qtd }}</h3>
          </div>
        </div>
        <b-table
          :items="detalhes.items"
          small
          stacked="sm"
          style="font-size: 10px"
          :fields="[
            { key: 'id', label: 'Cód.' },
            { key: 'paciente', label: 'Paciente' },
            { key: 'usuario', label: 'Operador' },
            { key: 'vl_total', label: 'Valor' },
            { key: 'exame', label: 'Exame' },
          ]"
        >
          <template #cell(vl_total)="row">
            {{ row.item.vl_total | currency }}
          </template>
          <template #cell(vl_custo)="row">
            {{ row.item.vl_custo | currency }}
          </template>
        </b-table>
      </PrintComponent>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import PrintComponent from "../../../components/Print/PrintComponent.vue";
export default {
  components: {
    PrintComponent,
  },
  props: {
    dados: Object,
    dados2: Object,
    filtros: Object,
  },
  mounted() {},
  data() {
    return {
      detalhes: [],
      detalhesHeader: "",
      showDetails: true,
      printDetails: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    donePrintDetails() {
      this.showDetails = true;
    },
    imprimirDetalhes() {
      this.printDetails = false;
      this.showDetails = false;
      setTimeout(() => {
        this.printDetails = true;
      }, 300);
    },
    openDetalhes(tipo, ex, usu) {
      console.log(1212);
      this.printDetails = false;
      this.showDetails = true;

      this.detalhesHeader = `Periodo: ${moment(
        this.filtros.periodo.dt_i
      ).format("DD/MM/YYYY")} até ${moment(this.filtros.periodo.dt_f).format(
        "DD/MM/YYYY"
      )}`;

      if (ex) {
        this.detalhesHeader +=
          " | " + ex.exame + " - " + this.formatFloatToReal(ex.valor);
      }
      if (usu) {
        this.detalhesHeader += " | " + usu.usuario;
      }

      this.$nextTick();
      setTimeout(() => {
        let fil = [];
        if (tipo == 1) {
          fil = this.dados.lista.filter(
            (x) =>
              (!ex ||
                (ex &&
                  x.exame == ex.exame &&
                  ((tipo == 1 && x.valor == ex.valor) || tipo == 2))) &&
              (!usu || (usu && x.usuario == usu.usuario))
          );
        } else {
          console.log(ex, usu);
          fil = this.dados2.lista.filter(
            (x) =>
              (!ex || (ex && x.ex == ex)) && (!usu || (usu && x.usuario == usu))
          );
        }
        console.log("filtrado", fil);
        this.detalhes = { qtd: 0, items: [] };
        for (let deta of fil) {
          if (deta.qtd) {
            this.detalhes.qtd += deta.qtd;
          } else {
            this.detalhes.qtd += 1;
          }
          if (deta.detalhes) this.detalhes.items.push(...deta.detalhes);
          else this.detalhes.items.push(deta);
        }
        console.log("filtrado2", this.detalhes);

        this.printDetails = true;

        this.$bvModal.show("modal-detalhe-comparativo-cartoes");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>